<template>
  <gl-modal
    v-bind="$attrs"
    capitalize-title
    full-buttons
    :loading="loading"
    overflowing
    :side-valid="isDefaultFormState"
    submit-title="Save"
    title="Edit Address Info"
    width="750"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="onSubmit"
  >
    <div class="flex m-column mr-1">
      <div class="flex-1 mr-4 m-mr-1">
        <gl-input
          v-model="formData.owner"
          class="gl-modal__row"
          :height="40"
          label="address owner"
          name="addressOwner"
        />
        <div class="gl-input__label flex space-between">
          <div>
            Type
          </div>
        </div>
        <vSelect
          ref="typesSelect"
          v-model="formData.type"
          append-to-body
          :autoscroll="false"
          class="mb-4"
          :disabled="showAddTypeModal"
          :options="types"
          push-tags
          taggable
          @option:created="addNewType"
        >
          <template #list-header>
            <div
              class="top-list-item"
              @click="addNewType(setSearchVal('typesSelect', 'types'))"
            >
              + Add New Type
            </div>
          </template>
        </vSelect>
      </div>
      <div class="flex-1 mr-1">
        <gl-textarea
          v-model="formData.description"
          class="gl-modal__row"
          label="description"
          max-length="500"
          name="description"
          rules="max:500"
        />
      </div>
    </div>
    <div class="gl-input__label flex space-between">
      <div>
        Tags
      </div>
    </div>
    <vSelect
      ref="tagsSelect"
      v-model="formData.tagsList"
      append-to-body
      :autoscroll="false"
      class="tagged mb-4 gl-modal__row mr-1"
      :disabled="showAddModal"
      label="name"
      multiple
      :options="options"
      @option:created="addNewTag"
    >
      <template #list-header>
        <div
          class="top-list-item"
          @click="addNewTag(setSearchVal('tagsSelect', 'options'))"
        >
          + Add New Tag
        </div>
      </template>
    </vSelect>
    <div
      v-if="formData.tagsList && formData.tagsList.length"
      class="mb-4 mr-1 overflow-auto"
    >
      <div
        v-for="(tag, index) in formData.tagsList"
        :key="index"
        class="flex align-center mb-2"
      >
        <div class="flex-1 mr-4 min-w-200">
          <GlTag
            :score="tag.score"
            :tag="tag.name"
          />
        </div>

        <div class="flex-1 min-w-200">
          <gl-input
            v-model="tag.metadata"
            :height="40"
            label=""
            name="metadata"
            placeholder="Start typing a metadata"
          />
        </div>
      </div>
    </div>
    <createTagModal
      v-if="showAddModal"
      v-model="showAddModal"
      :tag="newTag"
      @add="sendNewTag"
      @close="cancelCreating"
    />
    <create-type-modal
      v-if="showAddTypeModal"
      v-model="showAddTypeModal"
      :type="newType"
      @add="sendNewType"
      @close="cancelCreatingType"
    />
    <confirmUpdateCalcModal
      v-if="showConfirmModal"
      v-model="showConfirmModal"
      :message="message"
      @close="closeConfirmHandler"
      @confirm="confirmHandler"
    />
  </gl-modal>
</template>

<script>
// Components
import GlModal from '@/components/gl-modal'
import GlInput from '@/components/gl-input'
import GlTextarea from '@/components/gl-textarea'
import GlRadio from '@/components/gl-radio-button'
import createTagModal from '../modals/createTagModal';
import CreateTypeModal from "@/pages/tagging/modals/createTypeModal";
import confirmUpdateCalcModal from "@/pages/scoring/modals/confirmUpdateCalcModal";
import vSelect from 'vue-select'
// Vuex
import { mapActions } from 'vuex'
import _, {isString} from "lodash";
// Utils
import { formatterRecalcApproveMessage } from "@/utils/text-formatter";
import { isDefaultType } from "@/utils/find-default-tags-types";
import GlTag from "@/components/gl-tag";

export default {
  components: {
    CreateTypeModal,
    GlTextarea,
    GlModal,
    GlInput,
    createTagModal,
    confirmUpdateCalcModal,
    vSelect,
    GlRadio,
    GlTag,
  },
  inheritAttrs: false,
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      tags: null,
      options: [],
      types: [],
      showAddModal: false,
      showAddTypeModal: false,
      showConfirmModal: false,
      message: '',
      skipCheck: false,
      loading: false,
      newTag: '',
      newType: '',
      localData: null,
      oldData: null,
      formData: {
        description: '',
        tags: [],
        meta: [],
        tagModel: '',
        type: '',
        owner: '',
      },
    }
  },
  computed: {
    isDefaultFormState() {
      return _.isEqual(this.formData, this.oldData)
    },
  },
  watch: {
    showAddModal: {
      handler(show) {
        if (show) {
          this.$refs.tagsSelect.closeSearchOptions()
        }
      },
    },
    showAddTypeModal: {
      handler(show) {
        if (show) {
          this.$refs.typesSelect.closeSearchOptions()
        }
      },
    },
  },
  mounted() {
    this.formData = JSON.parse(JSON.stringify(this.data))
    this.localData = JSON.parse(JSON.stringify(this.data))

    this.getTags({ count: 10000 })
      .then(({ data: { tagData } }) => this.options = tagData.map((tag) => ({
        ...tag,
        name: tag.name,
        tagId: tag._id
      })).reduce((acc, current) => {
        const existingIndex = acc.findIndex(item => item.name === current.name);
        if (existingIndex === -1) {
          acc.push(current);
        } else if (acc[existingIndex]._id > current._id) {
          acc[existingIndex] = current;
        }
        return acc;
      }, []))
    this.getTypes({ count: 10000 })
        .then(({ data: { typeData } }) => this.types = typeData
            .filter(type => !isDefaultType(type.name))
            .reduce((acc, current) => {
              const existingIndex = acc.findIndex(item => item.name === current.name);
              if (existingIndex === -1) {
                acc.push(current);
              } else if (acc[existingIndex]._id > current._id) {
                acc[existingIndex] = current;
              }
              return acc;
            }, [])
            .map(type => type.name))
    this.oldData = _.cloneDeep(this.formData)
  },
  methods: {
    isDefaultType,
    formatterRecalcApproveMessage,
    ...mapActions('tagging', ['updateWallet', 'getAddressTags','getTags', 'addTag']),
    ...mapActions('types', ['getTypes', 'addType']),
    confirmHandler() {
      this.skipCheck = true
      this.onSubmit()
    },
    closeConfirmHandler() {
      this.skipCheck = false
      this.showConfirmModal = false
    },
    onSubmit() {
      this.loading = true
      Object.keys(this.formData).forEach((k) => {
        if (isString(this.formData[k])) {
          this.formData[k] = this.formData[k].trim()
        }});

      this.formData.meta = this.formData.tagsList.map(item => item.metadata || '')
      this.formData.tags = this.formData.tagsList.map(item => item.name || '')

      this.updateWallet({ ...this.formData, skipCheck: this.skipCheck }).then(({ data, success }) => {
        if (success) {
          this.$emit('save', { ...this.formData })
          this.$emit('input', false)
          this.showConfirmModal = false
        } else {
          if (data.showPopup || data?.response?.messageData) {
            this.message = this.formatterRecalcApproveMessage ? this.formatterRecalcApproveMessage(data?.response?.messageData) : data.message
            this.showConfirmModal = true
          } else this.$toasted.global.error({ message: `${data.message}`})
        }
      }).finally(() =>
        {
          this.loading = false
        })
    },
    setSearchVal(ref, optionsDataName) {
      if (this.$refs[ref].filteredOptions.length === 1
          && !this[optionsDataName].includes(this.$refs[ref].search)) {
        return this.$refs[ref].search
      } else {
        return ''
      }
    },
    addNewTag(tag) {
      this.newTag = tag.name
      this.showAddModal = true
      this.$refs.tagsSelect.closeSearchOptions()
    },
    sendNewTag(name, score, metadata) {
      this.addTag({ name, score: Number(score), metadata }).then(({ data, success }) => {
        if (success) {
          this.showAddModal = false
          const index = this.formData.tagsList.findIndex(tag => tag.name === String(this.newTag))

          if (index !== -1) {
            this.formData.tagsList.splice(this.formData.tagsList.length - 1, 1)
          }

          this.formData.tagsList.push({ name, score: Number(score), metadata })
        } else {
          this.$toasted.global.error({ message: `${data.message}` })
        }
      })

    },
    sendNewType(name, score) {
      this.addType({ name, score: Number(score) }).then(({ data, success }) => {
        if (success) {
          this.showAddTypeModal = false
          this.formData.type = name
        } else {
          this.$toasted.global.error({ message: `${data.message}` })
        }
      })
    },
    addNewType(type) {
      this.newType = type
      this.showAddTypeModal = true
      this.$refs.typesSelect.closeSearchOptions()
    },
    cancelCreating(cancelingTag) {
      this.showAddModal = false
      const replaceTagIndex = this.$refs.tagsSelect._data.pushedTags.findIndex(tag => tag === cancelingTag)

      if (replaceTagIndex !== -1) {
        this.$refs.tagsSelect._data.pushedTags.splice(replaceTagIndex, 1)
        this.formData.tags.splice(this.formData.tags.length - 1, 1)
      }
    },
    cancelCreatingType(cancelingType) {
      this.showAddTypeModal = false
      const replaceTagIndex = this.$refs.typesSelect._data.pushedTags.findIndex(tag => tag === cancelingType)

      if (replaceTagIndex !== -1) {
        this.$refs.typesSelect._data.pushedTags.splice(replaceTagIndex, 1)
        this.formData.type = this.data.type
      }
    },
  },
}
</script>

<style>
.top-list-item {
  margin-left: 20px;
  padding-bottom: 3px;
  color: var(--reflex-bluet);
  cursor: pointer;
}
</style>
